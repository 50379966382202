.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
}

.chat-heading {
  color: #8efcec;
}

.chat-window {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  background-color: #f5f5f5;
  font-size: 14px;
}

.chat-message {
  display: flex;
  max-width: 60%;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 20px;
}

.chat-message.user {
  align-self: flex-end;
  background-color: #4db8ff;
  color: white;
}

.chat-message.assistant {
  align-self: flex-start;
  background-color: #eee;
  color: #333;
  text-align: left;
}

.chat-input-form {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
}

.chat-input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
}

.chat-submit-btn {
  background-color: #4db8ff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
}

.chat-submit-btn:hover {
  background-color: #3da6e6;
}

.chat-submit-btn:active {
  background-color: #3399cc;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #4db8ff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
